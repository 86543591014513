.mainContainerStyle {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.headerContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 16px 24px;
}

.headerTextStyle {
  font-family: 'NeueHass-Medium', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: var(--darkSlateGray);
}

.closeIconStyle {
  width: 26px;
  height: 26px;
  cursor: pointer;
}

.detailsContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  height: 100%;
}

.detailsDataWrapperStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.printImgContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.imageStyle {
  min-width: 96px;
  height: 96px;
  border-radius: 4px;
}

.printImgDetailsWrapperStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.printTypeTextStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: -0.008em;
  text-align: left;
  color: var(--darkSlateGray);
}

.imgNameTextStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.008em;
  text-align: left;
  color: var(--charleStonGreen);
}

.priceTextStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.008em;
  text-align: left;
  color: var(--charleStonGreen);
}

.priceValueTextStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.008em;
  text-align: left;
  color: var(--darkSlateGray);
}

.specificationsContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: scroll;
  height: calc(100vh - 416px);
}

.specificationsContainerStyle {
  scrollbar-width: auto;
}

.specificationsContainerStyle::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
}

.specificationsContainerStyle::-webkit-scrollbar-thumb {
  background: var(--dimGray);
  cursor: pointer;
  border-radius: 4px;
}

.specItemContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding-right: 6px;
}

.specItemDetailsSectionStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.itemHeaderTextStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: var(--darkSlateGray);
  border-bottom: 1px solid var(--darkSlateGray);
  width: fit-content;
  cursor: pointer;
}

.itemContentTextStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.008em;
  text-align: left;
  color: var(--charleStonGreen);
}

.itemPriceTextStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.008em;
  text-align: right;
  color: var(--darkSlateGray);
}

.itemPriceTextStyle {
  white-space: nowrap;
}

.btnSectionStyle {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1728px) {
  .headerTextStyle {
    font-size: 17px;
    line-height: 26px;
  }

  .closeIconStyle {
    width: 24px;
    height: 24px;
  }

  .printTypeTextStyle {
    font-size: 17px;
    line-height: 26px;
  }

  .imgNameTextStyle,
  .priceTextStyle,
  .priceValueTextStyle,
  .itemHeaderTextStyle,
  .itemContentTextStyle,
  .itemPriceTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1512px) {
  .headerTextStyle {
    font-size: 16px;
    line-height: 24px;
  }

  .imageStyle {
    min-width: 72px;
    height: 72px;
  }

  .printTypeTextStyle {
    font-size: 16px;
    line-height: 24px;
  }

  .imgNameTextStyle,
  .priceTextStyle,
  .priceValueTextStyle,
  .itemHeaderTextStyle,
  .itemContentTextStyle,
  .itemPriceTextStyle {
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 1366px) {
}

@media screen and (max-width: 1279px) {
}

@media screen and (max-width: 1139px) {
  .headerTextStyle {
    font-size: 15px;
    line-height: 22px;
  }

  .printTypeTextStyle {
    font-size: 15px;
    line-height: 22px;
  }
}

@media screen and (max-width: 985px) {
  .printImgContainerStyle {
    gap: 10px;
  }
}

@media screen and (max-width: 897px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 549px) {
  .headerContainerStyle {
    padding: 16px 20px;
  }

  .headerTextStyle {
    font-size: 18px;
    line-height: 26px;
  }

  .detailsContainerStyle {
    height: 100%;
    gap: 20px;
    padding: 20px;
    justify-content: space-between;
  }

  .printTypeTextStyle {
    font-size: 18px;
    line-height: 26px;
  }

  .imageStyle {
    min-width: 64px;
    height: 64px;
  }

  .imgNameTextStyle,
  .priceTextStyle,
  .priceValueTextStyle,
  .itemHeaderTextStyle,
  .itemContentTextStyle,
  .itemPriceTextStyle {
    font-size: 16px;
    line-height: 24px;
  }

  .btnStyle {
    width: 100%;
  }

  .mainContainerStyle {
    height: 100%;
  }
}

@media screen and (max-width: 379px) {
  .headerContainerStyle {
    padding: 16px;
  }

  .headerTextStyle {
    font-size: 15px;
    line-height: 22px;
  }

  .closeIconStyle {
    width: 22px;
    height: 22px;
  }

  .detailsContainerStyle {
    gap: 16px;
    padding: 16px;
  }

  .printTypeTextStyle {
    font-size: 15px;
    line-height: 22px;
  }

  .imgNameTextStyle,
  .priceTextStyle,
  .priceValueTextStyle,
  .itemHeaderTextStyle,
  .itemContentTextStyle,
  .itemPriceTextStyle {
    font-size: 14px;
    line-height: 20px;
  }

  .specificationsContainerStyle {
    gap: 12px;
  }

  .specItemContainerStyle {
    gap: 10px;
    padding-right: 6px;
  }
}
