.loaderContainerStyle {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: var(--lightMistGray);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loaderStyle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid var(--dimGray);
    border-top-color: var(--darkSlateGray);
    animation: loading 1s linear infinite;
}

@keyframes loading {
    to {
        transform: rotate(360deg);
    }
}