.alertModalContainerStyle {
  max-width: 360px;
  display: flex;
  flex-direction: column;
}
.descTextStyle {
  font-family: 'NeueHass-Medium', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.008em;
  text-align: left;
  color: var(--darkSlateGray);
  padding: 16px;
  border-bottom: 1px solid var(--lunarLight);
}
.btnContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px;
}
.applyBtnStyle {
  background-color: var(--white);
  border: 1px solid var(--darkSlateGray);
}
.applyBtnTitleStyle {
  color: var(--darkSlateGray);
}
@media screen and (max-width: 1728px) {
  .descTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1512px) {
  .alertModalContainerStyle {
    max-width: 320px;
  }
  .descTextStyle {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 1279px) {
}
@media screen and (max-width: 1139px) {
}
@media screen and (max-width: 985px) {
}
@media screen and (max-width: 897px) {
}
@media screen and (max-width: 767px) {
}
@media screen and (max-width: 549px) {
  .alertModalContainerStyle {
    max-width: 358px;
  }
  .descTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 379px) {
  .alertModalContainerStyle {
    max-width: 288px;
  }
  .descTextStyle {
    font-size: 14px;
    line-height: 20px;
    padding: 12px;
  }
  .btnContainerStyle {
    padding: 12px;
  }
}
