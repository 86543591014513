.layOutMainStyle {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  background-color: var(--lightCyan);
}

.layOutStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contentWrapperStyle {
  height: calc(100vh - 86px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  gap: 24px;
}

@media screen and (max-width: 897px) {
  .contentWrapperStyle {
    flex-direction: column;
    align-items: center;

    padding: 0;
    gap: 0;
    height: 100%;
    max-height: 100%;
    overflow: scroll;
  }
}
@media screen and (max-width: 549px) {
}
