/* ----------------------------------Desktop Styles Start---------------------------------- */
.selectBorderStyle {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
}

.topStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tabsBlockStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabsStyle {
  display: flex;
  align-items: center;
  gap: 6px;
}

.descpStyle,
.borderMsgStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.008em;
  color: var(--charleStonGreen);
}

.borderMsgStyle {
  color: var(--red);
}

.borderStyle,
.borderTopStyle,
.InAndOutSideStyle,
.dimensionStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.borderTopStyle {
  gap: 8px;
}

.borderTitleStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.borderLabelStyle,
.valueStyle,
.inputLabelStyle,
.inputStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.008em;
  color: var(--darkSlateGray);
}

.inpuContainertStyle {
  height: 50px;
}

.valueStyle,
.inputStyle {
  color: var(--charleStonGreen);
}

.activeCapsuleStyle {
  background: var(--whiteSmoke);
  border: 1px solid var(--darkSlateGray);
}

.inActiveCapsuleStyle {
  background: var(--lightGray);
}

.activeLabelText {
  color: var(--darkSlateGray);
}

.inActiveLabelText {
  color: var(--charleStonGreen);
}

.infoStyle {
  display: none;
}

.borderBottomStyle {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}

.InAndOutSideStyle,
.dimensionStyle {
  gap: 6px;
}

.radioBtnStyle {
  width: 24px;
  height: 24px;
}

.radioLabelStyle {
  font-size: 17px;
  line-height: 26px;
}

.bottomStyle {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.applyBtnStyle,
.prevBtnViewStyle {
  width: 100%;
  height: 51px;
}

.prevBtnViewStyle {
  background-color: var(--white);
}

.prevBtnTitleStyle {
  color: var(--darkSlateGray);
}

.applyTitleStyle {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.inputsSecStyle {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* ----------------------------------Desktop Styles End---------------------------------- */

/* styles starts at 1728px */

@media screen and (max-width: 1728px) {
  .inActiveLabelText,
  .activeLabelText,
  .descpStyle,
  .inputLabelStyle,
  .radioLabelStyle,
  .borderLabelStyle,
  .valueStyle,
  .inputStyle,
  .borderMsgStyle {
    font-size: 16px;
    line-height: 24px;
  }

  .radioBtnStyle {
    width: 22px;
    height: 22px;
  }

  .inpuContainertStyle {
    height: 48px;
  }

  .applyBtnStyle {
    height: 50px;
  }

  .applyTitleStyle {
    font-size: 17px;
    line-height: 26px;
  }
}

/* styles ends at 1513px */

/* styles starts at 1512px */
@media screen and (max-width: 1512px) {
  .inActiveLabelText,
  .activeLabelText,
  .descpStyle,
  .inputLabelStyle,
  .radioLabelStyle,
  .borderLabelStyle,
  .valueStyle,
  .inputStyle,
  .borderMsgStyle {
    font-size: 14px;
    line-height: 20px;
  }

  .radioBtnStyle {
    width: 20px;
    height: 20px;
  }

  .inpuContainertStyle {
    height: 44px;
  }

  .applyBtnStyle {
    height: 48px;
  }

  .applyTitleStyle {
    font-size: 16px;
    line-height: 24px;
  }
}

/* styles ends at 1367px */

/* styles starts at 1366px */
@media screen and (max-width: 1366px) {
}

/* styles ends at 1280px */

/* styles starts at 1279px */
@media screen and (max-width: 1279px) {
}

/* styles ends at 1140px */

/* styles starts at 1139px */
@media screen and (max-width: 1139px) {
  .applyBtnStyle {
    height: 46px;
  }

  .applyTitleStyle {
    font-size: 15px;
    line-height: 22px;
  }
}

/* styles ends at 986px */

/* styles starts at 985px */
@media screen and (max-width: 985px) {
}

/* styles ends at 898px */

/* styles starts at 897px */
@media screen and (max-width: 897px) {
}

/* styles ends at 768px */

/* styles starts at 767px */
@media screen and (max-width: 767px) {
}

/* styles ends at 550px */

/* styles starts at 549px */
@media screen and (max-width: 549px) {
  .selectBorderStyle {
    padding: 16px;
    gap: 12px;
  }

  .topStyle {
    gap: 12px;
  }

  .inActiveLabelText,
  .activeLabelText,
  .descpStyle,
  .inputLabelStyle,
  .radioLabelStyle,
  .borderLabelStyle,
  .valueStyle,
  .inputStyle,
  .borderMsgStyle {
    font-size: 14px;
    line-height: 24px;
  }

  .dimensionStyle,
  .descpStyle {
    display: none;
  }

  .InAndOutSideStyle {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .inpuContainertStyle {
    height: 48px;
  }

  .applyBtnStyle {
    height: 50px;
  }

  .applyTitleStyle,
  .prevBtnTitleStyle {
    font-size: 14px;
    line-height: 26px;
  }

  .infoStyle {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .infoIconStyle {
    width: 16px;
    height: 16px;
  }

  .imageStyle {
    width: 100%;
    height: 100%;
  }
  .applyBtnStyle,
  .prevBtnViewStyle {
    height: 36px;
  }
}

/* styles ends at 380px */

/* styles starts at 379px */
@media screen and (max-width: 379px) {
  .selectBorderStyle {
    padding: 16px;
    gap: 16px;
  }

  .inActiveLabelText,
  .activeLabelText,
  .descpStyle,
  .inputLabelStyle,
  .radioLabelStyle,
  .borderLabelStyle,
  .valueStyle,
  .inputStyle,
  .borderMsgStyle {
    font-size: 14px;
    line-height: 20px;
  }

  .inpuContainertStyle {
    height: 44px;
  }

  .applyBtnStyle {
    height: 38px;
  }

  .applyTitleStyle {
    font-size: 15px;
    line-height: 22px;
  }
}

/* styles ends at 380px */
