.modalContainerStyle {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--charcoalBlue);
  z-index: 2;
}

.modalContentStyle {
  max-width: 320px;
  width: 100%;
  background-color: var(--whiteSmoke);
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px var(--grayishBlue);
  border-radius: 4px;
}
