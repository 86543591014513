.cardContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 24px;
  background: var(--whiteSmoke);
}

.topCardSurfaceStyle {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 24px;
}

.topLeftCardContentStyle {
  display: flex;
  align-items: start;
  gap: 12px;
}

.topLeftCardMediaStyle {
  width: 96px;
  height: 96px;
  border-radius: 4px;
}

.cardContentStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.cardTitleStyle,
.priceTextStyle {
  font: 400 18px/27px 'NeueHass-Medium', sans-serif;
  letter-spacing: -0.008em;
  color: var(--darkSlateGray);
}

.specificationTitleStyle,
.specificationDetailStyle,
.quantityTextStyle,
.countTextStyles {
  font: 400 17px/26px 'NeueHass-Light', sans-serif;
  letter-spacing: -0.008em;
  color: var(--charleStonGreen);
}

.bottomCardSurfaceStyle {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-left: 106px;
}

.bottomLeftCardActionStyle {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.quantityTextStyle,
.countTextStyles {
  color: var(--darkSlateGray);
}

.bottomCardActionAreaStyle {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  border: 1px solid var(--platinum);
  padding: 6px 8px;
}

.minusIconStyle,
.plusIconStyle {
  width: 26px;
  height: 26px;
  cursor: pointer;
}

.deleteIconStyle {
  width: 26px;
  height: 38px;
  cursor: pointer;
}

/* --------------------------desktop styles start-------------------------- */
/* styles starts at 1728px */
@media screen and (max-width: 1728px) {

  .cardTitleStyle,
  .priceTextStyle {
    font-size: 17px;
    line-height: 26px;
  }

  .specificationTitleStyle,
  .specificationDetailStyle,
  .quantityTextStyle,
  .countTextStyles {
    font-size: 16px;
    line-height: 24px;
  }

  .minusIconStyle,
  .plusIconStyle {
    width: 24px;
    height: 24px;
  }

  .deleteIconStyle {
    width: 24px;
    height: 36px;
  }
}

/* styles ends at 1513px */
/* styles starts at 1512px */
@media screen and (max-width: 1512px) {

  .cardTitleStyle,
  .priceTextStyle {
    font-size: 16px;
    line-height: 24px;
  }

  .specificationTitleStyle,
  .specificationDetailStyle,
  .quantityTextStyle,
  .countTextStyles {
    font-size: 14px;
    line-height: 20px;
  }

  .minusIconStyle,
  .plusIconStyle {
    width: 20px;
    height: 20px;
  }

  .deleteIconStyle {
    width: 24px;
    height: 32px;
  }
}

/* styles ends at 1367px */
/* styles starts at 1366px */
@media screen and (max-width: 1366px) {}

/* styles ends at 1280px */
/* styles starts at 1279px */
@media screen and (max-width: 1279px) {
  .topLeftCardMediaStyle {
    width: 80px;
    height: 80px;
  }

  .bottomCardSurfaceStyle {
    margin-left: 92px;
  }
}

/* styles ends at 1140px */
/* styles starts at 1139px */
@media screen and (max-width: 1139px) {
  .topLeftCardMediaStyle {
    width: 72px;
    height: 72px;
  }

  .cardTitleStyle,
  .priceTextStyle {
    font-size: 15px;
    line-height: 22px;
  }

  .bottomCardSurfaceStyle {
    margin-left: 84px;
  }
}

/* styles ends at 986px */
/* styles starts at 985px */
@media screen and (max-width: 985px) {
  .deleteIconStyle {
    width: 22px;
    height: 32px;
  }
}

/* styles ends at 898px */
/* styles starts at 897px */
@media screen and (max-width: 897px) {}

/* styles ends at 768px */
/* styles starts at 767px */
@media screen and (max-width: 767px) {
  .topLeftCardMediaStyle {
    width: 64px;
    height: 64px;
  }

  .bottomCardSurfaceStyle {
    margin-left: 76px;
  }

  .deleteIconStyle {
    width: 22px;
    height: 22px;
  }
}

/* styles ends at 550px */
/* styles starts at 549px */
@media screen and (max-width: 549px) {
  .cardContainerStyle {
    gap: 16px;
    padding: 20px;
  }

  .topCardSurfaceStyle {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .cardTitleStyle,
  .priceTextStyle {
    font-size: 17px;
    line-height: 26px;
  }

  .specificationTitleStyle,
  .specificationDetailStyle,
  .quantityTextStyle,
  .countTextStyles {
    font-size: 16px;
    line-height: 24px;
  }

  .topRightCardContentStyle {
    margin-left: 76px;
  }

  .minusIconStyle,
  .plusIconStyle {
    width: 24px;
    height: 24px;
  }

  .deleteIconStyle {
    width: 24px;
    height: 36px;
  }
}

/* styles ends at 380px */
/* styles starts at 379px */
@media screen and (max-width: 379px) {
  .topLeftCardMediaStyle {
    min-width: 56px;
    width: 56px;
    height: 56px;
  }

  .cardContainerStyle {
    gap: 12px;
    padding: 16px;
  }

  .topLeftCardContentStyle {
    gap: 10px;
  }

  .topCardSurfaceStyle {
    gap: 12px;
  }

  .cardTitleStyle,
  .priceTextStyle {
    font-size: 15px;
    line-height: 22px;
  }

  .specificationTitleStyle,
  .specificationDetailStyle,
  .quantityTextStyle,
  .countTextStyles {
    font-size: 14px;
    line-height: 20px;
  }

  .topRightCardContentStyle,
  .bottomCardSurfaceStyle {
    margin-left: 66px;
  }

  .deleteIconStyle {
    width: 24px;
    height: 24px;
  }
}

/* styles ends at 380px */
/* --------------------------desktop styles start-------------------------- */
.cardContainerStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 24px;
  background: var(--whiteSmoke);
}

.topCardSurfaceStyle {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 24px;
}

.topLeftCardContentStyle {
  display: flex;
  align-items: start;
  gap: 12px;
}

.topLeftCardMediaStyle {
  width: 96px;
  height: 96px;
  border-radius: 4px;
}

.cardContentStyle {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.cardTitleStyle,
.priceTextStyle {
  font: 400 18px/27px 'NeueHass-Medium', sans-serif;
  letter-spacing: -0.008em;
  color: var(--darkSlateGray);
}

.specificationTitleStyle,
.specificationDetailStyle,
.quantityTextStyle,
.countTextStyles {
  font: 400 17px/26px 'NeueHass-Light', sans-serif;
  letter-spacing: -0.008em;
  color: var(--charleStonGreen);
}

.bottomCardSurfaceStyle {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-left: 106px;
}

.bottomLeftCardActionStyle {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.quantityTextStyle,
.countTextStyles {
  color: var(--darkSlateGray);
}

.bottomCardActionAreaStyle {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  border: 1px solid var(--platinum);
  padding: 6px 8px;
}

.minusIconStyle,
.plusIconStyle {
  width: 26px;
  height: 26px;
}

.deleteIconStyle {
  width: 26px;
  height: 38px;
}

/* --------------------------desktop styles start-------------------------- */
/* styles starts at 1728px */
@media screen and (max-width: 1728px) {

  .cardTitleStyle,
  .priceTextStyle {
    font-size: 17px;
    line-height: 26px;
  }

  .specificationTitleStyle,
  .specificationDetailStyle,
  .quantityTextStyle,
  .countTextStyles {
    font-size: 16px;
    line-height: 24px;
  }

  .minusIconStyle,
  .plusIconStyle {
    width: 24px;
    height: 24px;
  }

  .deleteIconStyle {
    width: 24px;
    height: 36px;
  }
}

/* styles ends at 1513px */
/* styles starts at 1512px */
@media screen and (max-width: 1512px) {

  .cardTitleStyle,
  .priceTextStyle {
    font-size: 16px;
    line-height: 24px;
  }

  .specificationTitleStyle,
  .specificationDetailStyle,
  .quantityTextStyle,
  .countTextStyles {
    font-size: 14px;
    line-height: 20px;
  }

  .minusIconStyle,
  .plusIconStyle {
    width: 20px;
    height: 20px;
  }

  .deleteIconStyle {
    width: 24px;
    height: 32px;
  }
}

/* styles ends at 1367px */
/* styles starts at 1366px */
@media screen and (max-width: 1366px) {}

/* styles ends at 1280px */
/* styles starts at 1279px */
@media screen and (max-width: 1279px) {
  .topLeftCardMediaStyle {
    width: 80px;
    height: 80px;
  }

  .bottomCardSurfaceStyle {
    margin-left: 92px;
  }
}

/* styles ends at 1140px */
/* styles starts at 1139px */
@media screen and (max-width: 1139px) {
  .topLeftCardMediaStyle {
    width: 72px;
    height: 72px;
  }

  .cardTitleStyle,
  .priceTextStyle {
    font-size: 15px;
    line-height: 22px;
  }

  .bottomCardSurfaceStyle {
    margin-left: 84px;
  }
}

/* styles ends at 986px */
/* styles starts at 985px */
@media screen and (max-width: 985px) {
  .deleteIconStyle {
    width: 22px;
    height: 32px;
  }
}

/* styles ends at 898px */
/* styles starts at 897px */
@media screen and (max-width: 897px) {}

/* styles ends at 768px */
/* styles starts at 767px */
@media screen and (max-width: 767px) {
  .topLeftCardMediaStyle {
    min-width: 64px;
    width: 64px;
    height: 64px;
  }

  .bottomCardSurfaceStyle {
    margin-left: 76px;
  }

  .deleteIconStyle {
    width: 22px;
    height: 22px;
  }
}

/* styles ends at 550px */
/* styles starts at 549px */
@media screen and (max-width: 549px) {
  .cardContainerStyle {
    gap: 16px;
    padding: 20px;
  }

  .topCardSurfaceStyle {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .cardTitleStyle,
  .priceTextStyle {
    font-size: 17px;
    line-height: 26px;
  }

  .specificationTitleStyle,
  .specificationDetailStyle,
  .quantityTextStyle,
  .countTextStyles {
    font-size: 16px;
    line-height: 24px;
  }

  .topRightCardContentStyle {
    margin-left: 76px;
  }

  .minusIconStyle,
  .plusIconStyle {
    width: 24px;
    height: 24px;
  }

  .deleteIconStyle {
    width: 24px;
    height: 36px;
  }
}

/* styles ends at 380px */
/* styles starts at 379px */
@media screen and (max-width: 379px) {
  .topLeftCardMediaStyle {
    min-width: 56px;
    width: 56px;
    height: 56px;
  }

  .cardContainerStyle {
    gap: 12px;
    padding: 16px;
  }

  .topLeftCardContentStyle {
    gap: 10px;
  }

  .topCardSurfaceStyle {
    gap: 12px;
  }

  .cardTitleStyle,
  .priceTextStyle {
    font-size: 15px;
    line-height: 22px;
  }

  .specificationTitleStyle,
  .specificationDetailStyle,
  .quantityTextStyle,
  .countTextStyles {
    font-size: 14px;
    line-height: 20px;
  }

  .topRightCardContentStyle,
  .bottomCardSurfaceStyle {
    margin-left: 66px;
  }

  .deleteIconStyle {
    width: 24px;
    height: 24px;
  }
}