* {
  margin: 0px;
  box-sizing: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
::-ms-overflow {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* colors */

:root {
  /* primary colors */
  --white: #fff;
  --black: #000;
  --red: red;
  --green: #00cc00;
  --tomato: #ff3300;
  /* gray colors */
  --lightShadeGray: #ccc;
  --lightGray: #f7f7f7;

  /* gray colors */
  --lightShadeGray: #ccc;
  --dimGray: #aaa;
  --lightCyan: #edeeee;
  --darkSlateGray: #292d32;
  --charleStonGreen: #292d328f;
  --platinum: #e1e2e2;
  --slateGray: #78849514;
  --charcoalBlue: #292d3240;
  --grayishBlue: #292d324d;
  --lightMistGray: #edeeeedf;
  --lunarLight: #ebebeb;
  --steelGray: #7f8285;
  --transparentBlack: #00000033;
  --paleSilver: #e9e9e9;
  --paleRose: #ebe8e8;
  --softSnow: #f6f6f6;
  --ashGray: #d0d0d0;
  /* red colors */
  --lavenderBlush: #eadfe8;
  --byzantium: #6d175e;
  --byzantiumLight: #6d175e8f;

  /* white colors */
  --whiteSmoke: #fbfbfb;
  /* blue colors */
  --lightBlue: #6699cc;
  --mediumSlateBlue: #336699;
}

/* dark background colors */

/* [data-theme='dark'] {
  background-color: var(--black);
} */

/* fonts */

/* Desc Font  */
@font-face {
  font-family: 'NeueHass-Light';
  src: url('../src/assets/fonts/NeueHaasGroteskTextPro-55Roman.otf');
}

/* Title Font */
@font-face {
  font-family: 'NeueHass-Medium';
  src: url('../src/assets/fonts/NeueHaasGroteskDisplayPro-55Roman.otf');
}

.advanced-cropper {
  background-color: transparent !important;
}

.advanced-cropper-fade--visible {
  opacity: 1 !important;
  visibility: visible !important;
}

.advanced-cropper-wrapper {
  opacity: 1 !important;
  visibility: visible !important;
}

.advanced-cropper-fade {
  opacity: 1 !important;
  visibility: visible !important;
}

.advanced-cropper-wrapper__fade {
  opacity: 1 !important;
  visibility: visible !important;
}
