.networkToasterStyle {
    position: fixed;
    inset: auto 20px 20px auto;
    padding: 10px;
    border-radius: 5px;
    color: var(--white);
    font-size: 14px;
    text-align: center;
    transition: opacity 0.3s, transform 0.3s;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.onLine {
    font: 600 16px/32px 'NeueHass-Light', sans-serif;
    background-color: var(--green);
}

.offLine {
    font: 600 16px/32px 'NeueHass-Light', sans-serif;
    background-color: var(--tomato);
}