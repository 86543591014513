/* ----------------------------------Desktop Styles Start---------------------------------- */
.headerSectionStyle {
  width: 100%;
  height: 62px;
  margin-inline: auto;
  background: var(--whiteSmoke);
}

.desktopStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
}

.leftStyle {
  width: 26px;
  height: 26px;
  cursor: pointer;
  position: relative;
}

.cartCountTextStyles {
  display: flex;
  justify-content: center;
  min-width: 20px;
  min-height: 20px;
  background-color: var(--byzantium);
  border: 1px solid var(--whiteSmoke);
  border-radius: 12px;
  position: absolute;
  top: -6px;
  right: -6px;
  font: normal 11px/20px '', sans-serif;
  letter-spacing: -0.008em;
  color: var(--whiteSmoke);
}

.middleStyle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.priceTextStyle {
  font: 500 18px/26px 'NeueHass-Light', sans-serif;
  color: var(--darkSlateGray);
}

.downArrowStyle {
  width: 20px;
  height: 20px;
  margin-left: 2px;
}

.cartAndUserViewStyle {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.profileImgStyle {
  position: relative;
  width: 20px;
  height: 20px;
}

.usersActionsBlockStyle {
  position: absolute;
  top: 50px;
  right: 10px;
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--white);
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px var(--grayishBlue);
  z-index: 3;
}

.userDetailsStyle {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.userNameAndEmailStyle {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.userNameStyle {
  font: 500 17px/26px 'NeueHass-Light', sans-serif;
  color: var(--darkSlateGray);
}

.userEmailStyle,
.userActionTextStyle {
  font: 400 17px/26px 'NeueHass-Medium', sans-serif;
  color: var(--darkSlateGray);
}

.userActionStyle {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.userActionItemStyle {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.addToCartBtnStyle {
  width: 130px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--whiteSmoke);
  border-radius: 32px;
  border: 1px solid var(--darkSlateGray);
  padding: 0px;
}

.addToCartTitleStyle {
  font: 500 17px/26px 'NeueHass-Light', sans-serif;
  color: var(--darkSlateGray);
}

.mobilePriceSectionStyle {
  display: none;
}

.modalContentStyle {
  max-width: 505px;
}

.cartModalContentStyle {
  max-width: 816px;
}

.headerContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  gap: 12px;
  border-bottom: 1px solid var(--platinum);
}

.cartDataBlockStyle {
  max-height: 460px;
  height: 100%;
  overflow-y: scroll;
}

.imgStyle {
  width: 26px;
  height: 26px;
  cursor: pointer;
}

.headerTextStyle,
.noDataFoundTextStyle {
  font-family: 'NeueHass-Medium', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: var(--darkSlateGray);
}

.noDataFoundTextStyle {
  text-align: center;
  padding: 10px;
}

.btnContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 0 24px 24px 24px;
}

.createBtnStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--whiteSmoke);
  border-radius: 32px;
  border: 1px solid var(--darkSlateGray);
  opacity: 0px;
  padding: 12px 24px;
}

.createTitleStyle {
  font: 500 17px/26px 'NeueHass-Light', sans-serif;
  color: var(--darkSlateGray);
}

/* ----------------------------------Desktop Styles End---------------------------------- */

/* styles starts at 1728px */

@media screen and (max-width: 1728px) {
  .headerSectionStyle {
    height: 56px;
  }

  .priceTextStyle {
    font-size: 17px;
  }

  .downArrowStyle {
    width: 18px;
    height: 18px;
  }

  .addToCartBtnStyle {
    width: 125px;
    height: 36px;
  }

  .addToCartTitleStyle {
    font-size: 16px;
    line-height: 24px;
  }

  .imgStyle {
    width: 24px;
    height: 24px;
  }

  .headerTextStyle {
    font-size: 17px;
    line-height: 26px;
  }
}

/* styles ends at 1513px */

/* styles starts at 1512px */

@media screen and (max-width: 1512px) {
  .headerSectionStyle {
    height: 60px;
  }

  .leftStyle {
    width: 24px;
    height: 24px;
  }

  .priceTextStyle {
    font-size: 16px;
    line-height: 24px;
  }

  .addToCartBtnStyle {
    width: 115px;
    height: 32px;
  }

  .addToCartTitleStyle {
    font-size: 14px;
    line-height: 20px;
  }

  .headerTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}

/* styles ends at 1367px */

/* styles starts at 1366px */
@media screen and (max-width: 1366px) {
  .headerSectionStyle {
    height: 56px;
  }

  .downArrowStyle {
    width: 16px;
    height: 16px;
  }
}

/* styles ends at 1280px */

/* styles starts at 1279px */
@media screen and (max-width: 1279px) {
  .headerSectionStyle {
    height: 56px;
  }

  .downArrowStyle {
    width: 16px;
    height: 16px;
  }

  .cartModalContentStyle {
    max-width: 690px;
  }
}

/* styles ends at 1140px */

/* styles starts at 1139px */
@media screen and (max-width: 1139px) {
  .headerTextStyle {
    font-size: 15px;
    line-height: 22px;
  }
}

/* styles ends at 986px */

/* styles starts at 985px */
@media screen and (max-width: 985px) {
  .priceTextStyle {
    font-size: 15px;
    line-height: 22px;
  }

  .cartModalContentStyle {
    max-width: 547px;
  }
}

/* styles ends at 898px */

/* styles starts at 897px */
@media screen and (max-width: 897px) {
  .addToCartBtnStyle {
    width: 107px;
    height: 32px;
  }
}

/* styles ends at 768px */

/* styles starts at 767px */
@media screen and (max-width: 767px) {
  .cartModalContentStyle {
    max-width: 494px;
  }
}

/* styles ends at 550px */

/* styles starts at 549px */
@media screen and (max-width: 549px) {
  .headerSectionStyle {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }

  .desktopStyle {
    padding: 12px 20px;
    border-bottom: 1px solid var(--platinum);
  }

  .middleStyle {
    display: none;
  }

  .addToCartBtnStyle {
    width: 117px;
    height: 34px;
  }

  .addToCartTitleStyle,
  .priceTextStyle {
    font-size: 16px;
    line-height: 24px;
  }

  .mobilePriceSectionStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }

  .modalContentStyle {
    max-width: 100%;
    height: calc(100vh - 60px);
    position: absolute;
    bottom: 0;
  }

  .cartModalContentStyle {
    max-width: 100%;
    height: calc(100vh - 60px);
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .headerContainerStyle {
    padding: 16px 20px;
  }

  .btnContainerStyle {
    justify-content: space-between;
    gap: 8px;
    padding: 0 20px 20px 20px;
  }

  .createBtnStyle {
    width: 100%;
  }

  .checkOutBtnStyle {
    width: 100%;
  }
}

/* styles ends at 380px */

/* styles starts at 379px */
@media screen and (max-width: 379px) {
  .desktopStyle {
    padding: 12px 16px;
  }

  .leftStyle {
    width: 20px;
    height: 20px;
  }

  .addToCartBtnStyle {
    width: 99px;
    height: 28px;
  }

  .addToCartTitleStyle,
  .priceTextStyle {
    font-size: 14px;
    line-height: 20px;
  }

  .downArrowStyle {
    width: 14px;
    height: 14px;
  }

  .headerContainerStyle {
    padding: 16px;
  }
}

/* styles ends at 380px */
