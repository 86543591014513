.containerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}

.noteTextStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.008em;
  color: var(--charleStonGreen);
}

.papersViewStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: calc(100vh - 450px);
  height: fit-content;
  overflow: hidden;
  overflow-y: scroll;
}

.papersViewStyle {
  scrollbar-width: auto;
}

.papersViewStyle::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
}

.papersViewStyle::-webkit-scrollbar-thumb {
  background: var(--dimGray);
  cursor: pointer;
  border-radius: 4px;
}

.paperWrapperStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
  border: 1px solid var(--platinum);
  border-radius: 4px;
  cursor: pointer;
}

.checkboxViewStyle {
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid var(--charleStonGreen);
}

.activeCheckboxViewStyle {
  background-color: var(--black);
  min-width: 24px;
  min-height: 24px;
}

.checkIconViewStyle {
  width: 100%;
  height: 100%;
}

.imgStyle {
  min-width: 12px;
  max-width: 12px;
  min-height: 12px;
  max-height: 12px;
}

.contentViewStyle {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
}

.contentTitleTextStyle {
  font-family: 'NeueHass-Medium', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.008em;
  color: var(--darkSlateGray);
}

.contentDescTextStyle {
  font-family: 'NeueHass-Medium', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.008em;
  color: var(--charleStonGreen);
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.moreTextStyle {
  font-family: 'NeueHass-Medium', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.008em;
  color: var(--darkSlateGray);
  text-decoration: underline;
  width: fit-content;
}

.moreTextStyle:hover {
  cursor: pointer;
  color: var(--lightBlue);
}

.imgContainerStyle {
  min-width: 96px;
  min-height: 96px;
}

.btnViewStyle,
.prevBtnViewStyle {
  width: 100%;
}

.prevBtnViewStyle {
  background-color: var(--white);
}

.prevBtnTitleStyle {
  color: var(--darkSlateGray);
}

.bottomBtnBlockStyle {
  display: flex;
  gap: 8px;
}

/* modal style  */
.modalViewStyle {
  max-width: 608px;
  height: auto;
  display: flex;
  flex-direction: column;
}

.modalSubViewStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}

.modalHeaderViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.closeIconViewStyle {
  min-width: 26px;
  min-height: 26px;
  cursor: pointer;
}

.modalHeaderTextStyle {
  font-family: 'NeueHass-Medium', sans-serif;
  font-size: 26px;
  font-weight: 500;
  line-height: 34px;
  color: var(--darkSlateGray);
}

.modalContentViewStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.paperImgViewStyle {
  width: 100%;
  height: 288px;
}

.modalContentTextStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.008em;
  color: var(--charleStonGreen);
}

.viewGalleryTextStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.008em;
  color: var(--darkSlateGray);
  text-decoration: underline;
  width: fit-content;
  cursor: pointer;
}

@media screen and (max-width: 1728px) {
  .noteTextStyle,
  .contentTitleTextStyle,
  .moreTextStyle,
  .modalContentTextStyle,
  .viewGalleryTextStyle {
    font-size: 16px;
    line-height: 24px;
  }

  .contentDescTextStyle {
    font-size: 15px;
    line-height: 21px;
  }

  .modalHeaderTextStyle {
    font-size: 24px;
    line-height: 32px;
  }
}

@media screen and (max-width: 1512px) {
  .noteTextStyle,
  .contentTitleTextStyle,
  .moreTextStyle,
  .modalContentTextStyle,
  .viewGalleryTextStyle {
    font-size: 14px;
    line-height: 20px;
  }

  .contentDescTextStyle {
    font-size: 13px;
    line-height: 18px;
  }

  .checkboxViewStyle {
    max-width: 20px;
    min-width: 20px;
    max-height: 20px;
    min-height: 20px;
  }

  .imgContainerStyle {
    min-width: 72px;
    min-height: 72px;
  }

  .modalHeaderTextStyle {
    font-size: 22px;
    line-height: 30px;
  }
}

@media screen and (max-width: 1279px) {
  .modalViewStyle {
    max-width: 505px;
  }

  .modalHeaderTextStyle {
    font-size: 20px;
    line-height: 28px;
  }
}

@media screen and (max-width: 1139px) {
  .imgContainerStyle {
    min-width: 64px;
    min-height: 64px;
  }
}

@media screen and (max-width: 985px) {
  .imgContainerStyle {
    min-width: 56px;
    min-height: 56px;
  }
}

@media screen and (max-width: 897px) {
}

@media screen and (max-width: 767px) {
  .modalViewStyle {
    max-width: 494px;
  }
}

@media screen and (max-width: 549px) {
  .containerStyle {
    padding: 10px;
    gap: 12px;
    height: 100vh;
    justify-content: space-between;
    background-color: var(--white);
  }

  .subContainerStyle {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .papersViewStyle {
    max-height: 100%;
    height: fit-content;
    overflow-y: scroll;
  }

  .noteTextStyle,
  .contentTitleTextStyle,
  .moreTextStyle,
  .modalContentTextStyle,
  .viewGalleryTextStyle {
    font-size: 14px;
    line-height: 24px;
  }

  .contentDescTextStyle {
    font-size: 14px;
    line-height: 20px;
  }

  .imgContainerStyle {
    min-width: 64px;
    min-height: 64px;
  }

  .modalViewStyle {
    position: absolute;
    max-width: 100%;
    bottom: 0;
  }

  .modalHeaderTextStyle {
    font-size: 22px;
    line-height: 30px;
  }

  .modalViewStyle {
    height: 100%;
  }

  .modalSubViewStyle {
    overflow-y: scroll;
  }

  .modalViewStyle {
    border-radius: 0px;
  }

  .addToCarBtnTitleStyle,
  .prevBtnTitleStyle {
    font: 400 14px / 26px 'NeueHass-Light', sans-serif;
  }

  .btnViewStyle,
  .prevBtnViewStyle {
    width: 100%;
    height: 36px;
  }
}
