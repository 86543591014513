/* .radioBtnContainerStyle {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
}

.radioBtnStyle,
.activeRadioBtnstyle {
  position: relative;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  border: 1px solid var(--charleStonGreen);
  background-color: var(--whiteSmoke);
  box-shadow: 3px 3px 10px 0px var(--slateGray);
}

.activeRadioBtnStyle {
  transition: 0.3s;
  border-color: var(--darkSlateGray);
  background-color: var(--darkSlateGray);
  box-shadow: 3px 3px 10px 0px var(--slateGray);
}

.checkIconStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  display: flex;
  width: 12px;
  height: 12px;
  transition: 0.3s;
  z-index: 1;
}

.labelStyle,
.activeLabelStyle {
  color: var(--charleStonGreen);
  font: normal 400 14px/20px 'NeueHass-Light', sans-serif;
  letter-spacing: -0.008em;
}

.activeLabelStyle {
  color: var(--darkSlateGray);
} */

.radioBtnContainerStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.radioWrapperStyle {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid var(--charleStonGreen);
}

.checkIconStyle {
  width: 100%;
  height: 100%;
}
.imgStyle {
  min-width: 12px;
  min-height: 12px;
  max-width: 12px;
  max-height: 12px;
}
.activeViewStyle {
  background-color: var(--black);
}

.labelTextStyle {
  color: var(--charleStonGreen);
  font: normal 400 14px/20px 'NeueHass-Light', sans-serif;
  letter-spacing: -0.008em;
}

.activeLabelStyle {
  color: var(--darkSlateGray);
}

@media screen and (max-width: 1728px) {
  .labelTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1512px) {
  .labelTextStyle {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 549px) {
  .labelTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
