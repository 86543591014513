.btnContainerStyle,
.btnDisabledStyle {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: fit-content;
  padding: 12px 24px;
  height: 44px;
  border-radius: 32px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--darkSlateGray);
  background-color: var(--darkSlateGray);
  cursor: pointer;
}

.btnDisabledStyle {
  opacity: 0.5;
}

.titleStyle {
  font: 400 17px/26px 'NeueHass-Light', sans-serif;
  color: var(--whiteSmoke);
  letter-spacing: -0.008em;
  white-space: nowrap;
  word-break: keep-all;
}

.btnImageContainerStyle {
  width: 34px;
  height: 34px;
  border-radius: 24px;
}

.btnImageStyle {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.loaderStyle {
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top: 2px solid #0a2037;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
