.rangeInputStyle,
.disabledRangeStyle {
  height: 6px;
  -webkit-appearance: none;
  width: 100%;
}

.rangeInputStyle:focus,
.disabledRangeStyle:focus {
  outline: none;
}

.rangeInputStyle::-webkit-slider-runnable-track,
.disabledRangeStyle::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: linear-gradient(
    to right,
    var(--darkSlateGray) var(--slider-value),
    var(--lavenderBlush) var(--slider-value)
  );
  border-radius: 18px;
}

.rangeInputStyle::-webkit-slider-thumb {
  height: 24px;
  width: 24px;
  border-radius: 22px;
  background: var(--whiteSmoke);
  border: 1px solid var(--darkSlateGray);
  box-shadow: 0px 1px 3px 0px var;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}
.disabledRangeStyle::-webkit-slider-thumb {
  height: 24px;
  width: 24px;
  border-radius: 22px;
  background: var(--whiteSmoke);
  border: 1px solid var(--charcoalBlue);
  box-shadow: 0px 1px 3px 0px var;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}
.rangeInputStyle::-moz-range-track,
.disabledRangeStyle::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  border-radius: 18px;
  background: linear-gradient(
    to right,
    var(--darkSlateGray) var(--slider-value),
    var(--lavenderBlush) var(--slider-value)
  );
}

.rangeInputStyle::-moz-range-thumb,
.disabledRangeStyle::-moz-range-thumb {
  height: 24px;
  width: 24px;
  border-radius: 22px;
  background: var(--whiteSmoke);
  border: 1px solid var(--charcoalBlue);
  box-shadow: 0px 1px 3px 0px var(--grayishBlue);
  cursor: pointer;
}

.rangeInputStyle::-ms-track,
.disabledRangeStyle::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  border-color: transparent;
  color: transparent;
  background: linear-gradient(
    to right,
    var(--darkSlateGray) var(--slider-value),
    var(--lavenderBlush) var(--slider-value)
  );
}

.rangeInputStyle::-ms-fill-lower,
.disabledRangeStyle::-ms-fill-lower {
  background: var(--lavenderBlush);
  border-radius: 36px;
}

.rangeInputStyle::-ms-fill-upper,
.disabledRangeStyle::-ms-fill-upper {
  background: var(--lavenderBlush);
  border-radius: 36px;
}

.rangeInputStyle::-ms-thumb,
.disabledRangeStyle::-ms-thumb {
  margin-top: 1px;
  height: 24px;
  width: 24px;
  border-radius: 22px;
  background: var(--whiteSmoke);
  border: 1px solid var(--charcoalBlue);
  box-shadow: 0px 1px 3px 0px var(--grayishBlue);
  cursor: pointer;
}

.rangeInputStyle:focus::-ms-fill-lower,
.disabledRangeStyle:focus::-ms-fill-lower {
  background: var(--lavenderBlush);
}

.rangeInputStyle:focus::-ms-fill-upper,
.disabledRangeStyle:focus::-ms-fill-upper {
  background: var(--lavenderBlush);
}
