/* --------------------------------desktop styles start-------------------------------- */
.menuContainerStyle {
  min-width: 30vw;
  max-width: 30vw;
  width: 100%;
  max-height: 100%;
  height: fit-content;
  border-radius: 4px;
  background: white;
  display: flex;
  flex-direction: column;
  background-color: var(--whiteSmoke);
}

.topBlockStyle,
.supplementaryTopBlockStyle {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid var(--platinum);
}

.supplementaryTopBlockStyle {
  padding: 16px;
}

.leftArrowStyle,
.rightArrowImageStyle {
  width: 26px;
  height: 26px;
  cursor: pointer;
}

.supplementaryMenuTitleStyle,
.menuTitleStyle {
  text-align: left;
  font: 500 26px/34px 'NeueHass-Medium', sans-serif;
  color: var(--darkSlateGray);
}

.bottomContainerStyle,
.tabTextStyle {
  display: flex;
  flex-direction: column;
}
.bottomContainerStyle {
  gap: 24px;
  padding: 24px;
}

.bottomBlockStyle,
.bottomSubBlockStyle {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
}

.bottomSubBlockStyle {
  justify-content: unset;
  padding: 0px;
  cursor: pointer;
}

.menuImgStyle {
  min-width: 28px;
  width: 28px;
  height: 28px;
}

.imageStyle {
  object-fit: unset;
}

.menuDetailsStyle {
  max-width: 278px;
  width: 100%;
}

.menuItemTitleStyle,
.activeMenuItemTextStyle {
  font: 500 18px/27px 'NeueHass-Medium', sans-serif;
  color: var(--charleStonGreen);
}

.activeMenuItemTextStyle {
  color: var(--darkSlateGray);
  font-family: 'NeueHass-Medium';
}

.menuSubTitleStyle,
.activeMenuSubTitleStyle {
  font: 400 17px/26px 'NeueHass-Light', sans-serif;
  color: var(--charleStonGreen);
  letter-spacing: -0.008em;
}

.activeMenuSubTitleStyle {
  color: var(--charleStonGreen);
}

.rightArrowImageStyle {
  width: 26px;
  height: 26px;
  cursor: pointer;
}

/* --------------------------------desktop styles start-------------------------------- */

@media screen and (max-width: 1728px) {
  .menuContainerStyle {
    min-width: 444px;
    max-width: 444px;
    width: 100%;
  }
  .supplementaryMenuTitleStyle,
  .menuTitleStyle {
    font-size: 24px;
    line-height: 32px;
  }
  .menuItemTitleStyle,
  .activeMenuItemTextStyle {
    font-size: 17px;
    line-height: 26px;
  }
  .menuSubTitleStyle,
  .activeMenuSubTitleStyle {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1512px) {
  .menuContainerStyle {
    min-width: 400px;
    max-width: 400px;
    width: 100%;
  }
  .supplementaryMenuTitleStyle,
  .menuTitleStyle {
    font-size: 22px;
    line-height: 30px;
  }
  .menuImgStyle {
    min-width: 26px;
    width: 26px;
    height: 26px;
  }

  .menuItemTitleStyle,
  .activeMenuItemTextStyle {
    font-size: 16px;
    line-height: 24px;
  }

  .menuSubTitleStyle,
  .activeMenuSubTitleStyle {
    font-size: 14px;
    line-height: 20px;
  }
  .rightArrowImageStyle {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 1279px) {
  .menuContainerStyle {
    min-width: 378px;
    max-width: 378px;
    width: 100%;
  }
  .supplementaryMenuTitleStyle,
  .menuTitleStyle {
    font-size: 20px;
    line-height: 28px;
  }
}
@media screen and (max-width: 1139px) {
  .menuContainerStyle {
    min-width: 367px;
    max-width: 367px;
    width: 100%;
  }

  .menuItemTitleStyle,
  .activeMenuItemTextStyle {
    font-size: 15px;
    line-height: 22px;
  }
}
@media screen and (max-width: 985px) {
  .menuContainerStyle {
    min-width: 345px;
    max-width: 345px;
    width: 100%;
  }
}
@media screen and (max-width: 897px) {
  .menuContainerStyle {
    min-width: 494px;
    max-width: 494px;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .menuContainerStyle {
    min-width: 502px;
    max-width: 502px;
    width: 100%;
  }
}
@media screen and (max-width: 549px) {
  .menuContainerStyle {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
  .topBlockStyle,
  .supplementaryTopBlockStyle {
    /* padding: 16px 20px; */
    padding: 12px;
  }
  .bottomContainerStyle {
    /* padding: 24px 20px; */
    padding: 16px;
  }
  .bottomBlockStyle,
  .bottomSubBlockStyle {
    gap: 10px;
  }
  .supplementaryMenuTitleStyle,
  .menuTitleStyle {
    /* font-size: 22px;
    line-height: 30px; */
    font-size: 16px;
    line-height: 24px;
  }

  .menuItemTitleStyle,
  .activeMenuItemTextStyle {
    /* font-size: 18px;
    line-height: 26px; */
    font-size: 16px;
    line-height: 24px;
  }

  .menuSubTitleStyle,
  .activeMenuSubTitleStyle {
    font-size: 16px;
    line-height: 24px;
  }
  .menuImgStyle {
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 379px) {
  .topBlockStyle,
  .supplementaryTopBlockStyle {
    padding: 16px;
  }
  .bottomContainerStyle {
    padding: 16px;
    gap: 20px;
  }

  .menuImgStyle {
    min-width: 22px;
    width: 22px;
    height: 22px;
  }
  .supplementaryMenuTitleStyle,
  .menuTitleStyle {
    font-size: 20px;
    line-height: 28px;
  }

  .menuItemTitleStyle,
  .activeMenuItemTextStyle {
    font-size: 15px;
    line-height: 22px;
  }

  .menuSubTitleStyle,
  .activeMenuSubTitleStyle {
    font-size: 14px;
    line-height: 20px;
  }
  .rightArrowImageStyle {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }
}
