.containerStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--white);
}
.labelStyle {
  color: var(--desc-color80);
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  text-wrap: nowrap;
}
.pointerStyle {
  cursor: pointer;
}
