.containerStyle {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  width: 100%;
  overflow: scroll;
}

.containerStyle {
  scrollbar-width: auto;
}

.containerStyle::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
}

.containerStyle::-webkit-scrollbar-thumb {
  background: var(--dimGray);
  cursor: pointer;
  border-radius: 4px;
}

.tabsViewStyle {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
}

.tabViewStyle {
  width: 147px;
  height: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 12px 8px;
  border-radius: 4px;
  border: 1px solid var(--platinum);
  cursor: pointer;
}

.tabLabelStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--darkSlateGray);
}

.activeTabStyle {
  background-color: var(--lavenderBlush);
}

.noteTextStyle,
.ImageDimensionTextStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.008em;
  color: var(--charleStonGreen);
}

.adjustSizeViewStyle,
.unitsSectionStyle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.adjustSizeSubViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.rangeIntervalStyle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.intervalBarStyle {
  width: 1px;
  height: 4px;
  background-color: var(--charleStonGreen);
}

.adjustWithHightSectionStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.titleTextStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.008em;
  color: var(--darkSlateGray);
}

.btnViewStyle,
.prevBtnViewStyle {
  width: 100%;
}

.prevBtnViewStyle {
  background-color: var(--white);
}

.prevBtnTitleStyle {
  color: var(--darkSlateGray);
}

.bottomBtnBlockStyle {
  display: flex;
  gap: 8px;
}

.lockIconStyle {
  min-width: 24px;
  margin-top: 30px;
  cursor: pointer;
}

.ratiosViewStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
}

.capsuleContainerStyle {
  cursor: pointer;
}

.capsuleLableTextStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--charleStonGreen);
}

.selectRationDropDownBlockStyle {
  display: none;
}

.activeLabelStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--darkSlateGray);
}

.activeCapsuleStyle {
  border: 1px solid var(--darkSlateGray);
}

.infoIconStyle {
  display: none;
}

@media screen and (max-width: 1728px) {
  .tabViewStyle {
    width: 127px;
  }

  .tabLabelStyle,
  .noteTextStyle,
  .titleTextStyle,
  .capsuleLableTextStyle,
  .activeLabelStyle,
  .ImageDimensionTextStyle {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1512px) {
  .tabViewStyle {
    width: 112px;
  }

  .tabLabelStyle,
  .noteTextStyle,
  .titleTextStyle,
  .capsuleLableTextStyle,
  .activeLabelStyle,
  .ImageDimensionTextStyle {
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 1279px) {
  .tabViewStyle {
    width: 105px;
  }
}

@media screen and (max-width: 1139px) {
  .tabViewStyle {
    width: 101px;
  }
}

@media screen and (max-width: 985px) {
  .tabViewStyle {
    width: 94px;
  }
}

@media screen and (max-width: 897px) {
  .tabViewStyle {
    width: 144px;
  }
}

@media screen and (max-width: 767px) {
  .tabViewStyle {
    width: 146px;
  }
}

@media screen and (max-width: 549px) {
  .containerStyle {
    padding: 16px;
    gap: 12px;
  }

  .tabViewStyle {
    width: fit-content;
    height: 0px;
    gap: 0px;
    padding: 0px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    background-color: none;
  }

  .ratiosViewStyle {
    display: none;
  }

  .selectRationDropDownBlockStyle {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .selectRatioInputStyle {
    max-width: 100%;
    height: 30px;
  }

  .selectDropDownStyle {
    position: absolute;
    top: calc(100% + 4px);
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    width: 100%;
    max-height: 150px;
    height: 100%;
    overflow-y: scroll;
    border-radius: 4px;
    border: 1px solid var(--charleStonGreen);
  }

  .ratioTextStyle,
  .selectedRatioTextStyle {
    padding: 6px;
    font: 400 14px/20px 'NeueHass-Light', sans-serif;
    letter-spacing: -0.008em;
    color: var(--darkSlateGray);
  }

  .selectedRatioTextStyle {
    background-color: var(--platinum);
  }

  .tabsIconStyle {
    display: none;
  }

  .tabsMainViewStyle {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .infoStyle {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .tabsViewStyle {
    gap: 15px;
  }

  .tabViewStyle {
    width: 100%;
    padding: 8px;
    height: 20px;
    border-radius: 4px;
  }

  .tabsIconStyle {
    width: 20px;
    height: 20px;
  }

  .tabLabelStyle,
  .noteTextStyle,
  .titleTextStyle,
  .capsuleLableTextStyle,
  .activeLabelStyle,
  .radioBtnLabelStyle,
  .inputLabelStyle,
  .ImageDimensionTextStyle,
  .prevBtnTitleStyle,
  .addChangesBtnStyle,
  .ratioLabelStyle,
  .ratioInputStyle,
  .ratioInputStyle::placeholder {
    font-size: 14px;
    line-height: 24px;
  }

  .tabLabelStyle {
    white-space: nowrap;
  }

  .noteTextStyle {
    display: none;
  }

  .infoIconStyle {
    display: block;
    width: 16px;
    height: 16px;
  }

  .imageStyle {
    width: 100%;
    height: 100%;
  }

  .unitsSectionStyle {
    flex-direction: row;
    justify-content: space-between;
  }
  .btnViewStyle,
  .prevBtnViewStyle {
    width: 100%;
    height: 36px;
  }
}
