.containerStyle {
  border: 1px solid var(--platinum);
  width: fit-content;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 2px;
  background-color: var(--lightGray);
}
.labelStyle {
  font-family: 'NeueHass-Light', sans-serif;
  color: var(--charleStonGreen);
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}
.leftIconStyle {
}
.rightIconStyle {
}
