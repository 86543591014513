.uploadImgContainerStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  padding: 24px;
  overflow-y: scroll;
}

.uploadImgContainerStyle {
  scrollbar-width: auto;
}

.uploadImgContainerStyle::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
}

.uploadImgContainerStyle::-webkit-scrollbar-thumb {
  background: var(--dimGray);
  cursor: pointer;
  border-radius: 4px;
}

.topBlockStyle,
.topFileUploadRegionStyle {
  max-width: 457px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 24px;
  background-color: var(--whiteSmoke);
  border: 1px dashed var(--charcoalBlue);
  border-radius: 8px;
}

.topFileUploadRegionStyle {
  border: 2px dashed var(--mediumSlateBlue);
}

.uploadImgStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: var(--lavenderBlush);
  border-radius: 24px;
}

.uploadIconStyle {
  width: 24px;
  height: 24px;
}

.uploadImgInfoTitleStyle,
.uploadImgDescStyle {
  max-width: 360px;
  width: 100%;
  text-align: center;
  font: 400 17px/26px 'NeueHass-Medium', sans-serif;
  letter-spacing: -0.008em;
  color: var(--darkSlateGray);
}

.uploadImgDescStyle {
  font: 400 17px/26px 'NeueHass-Light', sans-serif;
  color: var(--charcoalBlue);
}

.dividerBlockStyle {
  display: flex;
  gap: 12px;
  align-items: center;
}

.leftDividerStyle,
.rightDividerStyle {
  width: 185px;
  max-width: 100%;
  border: 1px solid var(--lavenderBlush);
}

.orTextStyle,
.errorMessageStyle {
  font: normal 500 17px/26px 'NeueHass-Light', sans-serif;
  text-align: center;
  color: var(--darkSlateGray);
}

.fileUploadBtnStyle {
  font: normal 400 17px/26px 'NeueHass-Light', sans-serif;
  letter-spacing: -0.008em;
  color: var(--whiteSmoke);
  padding: 8px 24px;
  border-radius: 32px;
  background-color: var(--darkSlateGray);
}

.bottomBlockStyle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.bottomSubBlockStyle {
  position: relative;
  width: 147px;
}

.uploadedImageContainerStyle {
  width: 147px;
  height: 147px;
  border-radius: 4px;
}

.uploadedImgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.closeIconBlcokStyle {
  position: absolute;
  inset: 2px 4px auto auto;
  cursor: pointer;
}

.closeBgCotainerStyle {
  width: 24px;
  height: 24px;
}

.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--lightMistGray);
  z-index: 10;
}

.loader {
  border: 3px solid #ddd;
  border-top: 3px solid var(--darkSlateGray);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.errorMessageStyle {
  color: var(--red);
}

.applyChangesBtnStyle {
  margin: auto;
  width: 100%;
}

/* Media Query Start */
@media screen and (max-width: 1728px) {
  .uploadImgInfoTitleStyle,
  .uploadImgDescStyle {
    font: 400 16px/24px 'NeueHass-Medium', sans-serif;
  }

  .uploadImgDescStyle {
    font: 400 16px/24px 'NeueHass-Light', sans-serif;
  }

  .leftDividerStyle,
  .rightDividerStyle {
    width: 154px;
  }

  .orTextStyle {
    font: normal 500 16px/24px 'NeueHass-Light', sans-serif;
  }

  .fileUploadBtnStyle {
    font: normal 400 16px/24px 'NeueHass-Light', sans-serif;
    border-radius: 24px;
  }

  .bottomSubBlockStyle,
  .uploadedImageContainerStyle {
    width: 126px;
  }

  .uploadedImageContainerStyle {
    height: 126px;
  }
}

@media screen and (max-width: 1512px) {
  .uploadImgInfoTitleStyle,
  .uploadImgDescStyle {
    font: 400 14px/20px 'NeueHass-Medium', sans-serif;
  }

  .uploadImgDescStyle {
    font: 400 14px/20px 'NeueHass-Light', sans-serif;
  }

  .leftDividerStyle,
  .rightDividerStyle {
    width: 134px;
  }

  .orTextStyle {
    font: normal 500 14px/20px 'NeueHass-Light', sans-serif;
  }

  .fileUploadBtnStyle {
    font: normal 400 14px/20px 'NeueHass-Light', sans-serif;
  }

  .bottomSubBlockStyle,
  .uploadedImageContainerStyle {
    width: 112px;
  }

  .uploadedImageContainerStyle {
    height: 112px;
  }
}

@media screen and (max-width: 1366px) {
  .bottomSubBlockStyle,
  .uploadedImageContainerStyle {
    width: 112px;
  }

  .uploadedImageContainerStyle {
    height: 112px;
  }
}

@media screen and (max-width: 1279px) {
  .bottomSubBlockStyle,
  .uploadedImageContainerStyle {
    width: 104px;
  }

  .uploadedImageContainerStyle {
    height: 104px;
  }

  .leftDividerStyle,
  .rightDividerStyle {
    width: 122px;
  }
}

@media screen and (max-width: 1139px) {
  .bottomSubBlockStyle,
  .uploadedImageContainerStyle {
    width: 101px;
  }

  .uploadedImageContainerStyle {
    height: 101px;
  }

  .leftDividerStyle,
  .rightDividerStyle {
    width: 117px;
  }
}

@media screen and (max-width: 985px) {
  .bottomSubBlockStyle,
  .uploadedImageContainerStyle {
    width: 94px;
  }

  .uploadedImageContainerStyle {
    height: 94px;
  }

  .leftDividerStyle,
  .rightDividerStyle {
    width: 106px;
  }
}

@media screen and (max-width: 897px) {
  .fileUploadBtnStyle {
    padding: 4px 24px;
  }

  .bottomSubBlockStyle,
  .uploadedImageContainerStyle {
    width: 144px;
  }

  .uploadedImageContainerStyle {
    height: 112px;
  }

  .leftDividerStyle,
  .rightDividerStyle {
    width: 180px;
  }
}

@media screen and (max-width: 767px) {
  .bottomSubBlockStyle,
  .uploadedImageContainerStyle {
    width: 146px;
  }

  .uploadedImageContainerStyle {
    height: 112px;
  }

  .leftDividerStyle,
  .rightDividerStyle {
    width: 184px;
  }
}

@media screen and (max-width: 549px) {
  .uploadImgContainerStyle {
    /* padding: 10px; */
    padding: 0px;
    gap: 10px;
  }

  .bottomBlockStyle {
    gap: 0px;
  }

  .bottomSubBlockStyle {
    padding: 10px;
  }

  .topBlockStyle,
  .topFileUploadRegionStyle {
    padding: 10px;
    gap: 10px;
  }

  .hideTopBlockStyle {
    display: none;
  }

  .bottomBlockStyle {
    justify-content: center;
  }

  .fileUploadLabelStyle {
    font: normal 400 14px/24px 'NeueHass-Light', sans-serif;
    letter-spacing: -0.008em;
    color: var(--whiteSmoke);
    padding: 8px 24px;
    border-radius: 32px;
    background-color: var(--white);
    color: var(--darkSlateGray);
    border: 1px solid var(--darkSlateGray);
    white-space: nowrap;
  }

  .imgAndFileUploadBtnStyle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .bottomBtnStyle {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    border-top: 1px solid var(--platinum);
  }

  .closeIconBlcokStyle {
    inset: 14px 2px auto auto;
  }

  .applyChangesBtnStyle {
    padding: 6px;
    width: 40%;
    margin: 0px;
    height: 36px;
  }
  .applyChangesBtnTItleStyle {
    font: 400 16px/24px 'NeueHass-Medium', sans-serif;
  }

  .uploadImgInfoTitleStyle {
    font: 400 16px/24px 'NeueHass-Medium', sans-serif;
  }

  .uploadImgDescStyle {
    display: none;
  }

  .uploadImgDescStyle {
    font: 400 16px/24px 'NeueHass-Light', sans-serif;
  }

  .orTextStyle {
    font: normal 500 16px/24px 'NeueHass-Light', sans-serif;
  }

  .fileUploadBtnStyle {
    font: normal 400 16px/24px 'NeueHass-Light', sans-serif;
  }

  .bottomSubBlockStyle,
  .uploadedImageContainerStyle {
    width: 112px;
  }

  .uploadedImageContainerStyle {
    height: 112px;
  }

  .leftDividerStyle,
  .rightDividerStyle {
    width: 120px;
  }

  .loaderOverlay {
    position: absolute;
    top: 10px;
    left: 10px;
    right: -10px;
    bottom: 10px;
  }

  .errorMessageStyle {
    font: normal 500 14px/24px 'NeueHass-Light', sans-serif;
  }
}

@media screen and (max-width: 379px) {
  /* .uploadImgContainerStyle {
    padding: 16px;
    gap: 16px;
  }

  .topBlockStyle,
  .topFileUploadRegionStyle {
    padding: 16px;
    gap: 16px;
  }

  .uploadImgInfoTitleStyle,
  .uploadImgDescStyle {
    font: 400 14px/20px 'NeueHass-Medium', sans-serif;
  }

  .uploadImgDescStyle {
    font: 400 14px/20px 'NeueHass-Light', sans-serif;
  }

  .leftDividerStyle,
  .rightDividerStyle {
    width: 90px;
  }

  .orTextStyle {
    font: normal 500 14px/20px 'NeueHass-Light', sans-serif;
  }

  .fileUploadBtnStyle {
    font: normal 400 14px/20px 'NeueHass-Light', sans-serif;
  }

  .bottomSubBlockStyle,
  .uploadedImageContainerStyle {
    width: 90px;
  }

  .uploadedImageContainerStyle {
    height: 96px;
  } */
}

/* Media Query End */
