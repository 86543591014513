:root {
  --maximumWidth: fit-content;
  --maximumHeight: 100%;
}

.homeContainerStyles,
.homeContainerStyle1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.homeContainerStyle1 {
  gap: 12px;
}

.imageBannerWrapperStyle {
  max-width: 60vw;
  width: 100%;
  max-height: 60vh;
  height: 100%;
  opacity: 0.5;
  overflow: hidden;
}

.imageMainContainerStyle {
  max-width: 60vw;
  width: 100%;
  max-height: 60vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageWrapperStyle2 {
  max-width: fit-content;
  width: 100%;
  max-height: 60vh;
  height: 100%;
  opacity: 1;
  overflow: hidden;
}

.imageWrapperStyle {
  max-width: 60vw;
  width: 100%;
  max-height: 60vh;
  height: 100%;
  opacity: 1;
}

.cropperStyle {
  max-width: 50vw;
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageStyle2 {
  object-fit: cover;
}

.imageStyle,
.tranformedImgStyle {
  object-fit: contain;
  max-width: 100%;
  width: auto;
}

.tranformedImgStyle {
  transition: 0.5s ease-in;
}

.transformedImageStyle {
  transition: transform 0.3s ease;
}

.toolsSectionStyle {
  height: 50px;
}

.imageEditBlockStyle {
  display: flex;
  background-color: var(--whiteSmoke);
  border-radius: 4px;
  padding: 4px;
}

.editImgActionIconBlockStyle:hover {
  background-color: var(--lavenderBlush);
}

.editImgActionIconBlockStyle,
.activeEditImgActionStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  cursor: pointer;
  border-radius: 2px;
}

.activeEditImgActionStyle {
  background-color: var(--lavenderBlush);
  border-radius: 2px;
}

.editImgActionIconStyle {
  width: 26px;
  height: 26px;
}

.imageMainSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  width: 60vw;
}

.imageAndCropperSectionWrapperStyle {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  /* height: 100%; */
}

.imageHeightSectionStyle {
  width: 20px;
  height: 20px;
}

.imageUnitTextStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.008em;
  color: var(--steelGray);
  width: 70px;
  height: 20px;
}

.imageUnitHeightTextStyle {
  font-family: 'NeueHass-Light', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.008em;
  color: var(--steelGray);
  width: 20px;
  height: 70px;
  writing-mode: vertical-rl;
}

.outsideDiv1 {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}

.outsideDiv2 {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}

.outsideDiv3 {
  position: absolute;
  top: 50%;
  right: 0;
  height: 100%;
  transform: translateY(-50%);
}

.outsideDiv4 {
  position: absolute;
  top: 50%;
  left: 0;
  height: 100%;
  transform: translateY(-50%);
}

.windowFitImgStyle {
  overflow: unset;
}
.outsideDiv1 {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}
.outsideDiv2 {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}
.outsideDiv3 {
  position: absolute;
  top: 50%;
  right: 0;
  height: 100%;
  transform: translateY(-50%);
}
.outsideDiv4 {
  position: absolute;
  top: 50%;
  left: 0;
  height: 100%;
  transform: translateY(-50%);
}
@media screen and (max-width: 1728px) {
  /* .windowFitImgStyle {
    --maximumWidth: 1440px;
  } */
}

@media screen and (max-width: 1512px) {
  /* .windowFitImgStyle {
    --maximumWidth: 1294px;
  } */
}

@media screen and (max-width: 1366px) {
  /* .windowFitImgStyle {
    --maximumWidth: 1207px;
  } */
}

@media screen and (max-width: 1279px) {
  /* .windowFitImgStyle {
    --maximumWidth: 1067px;
  } */
  .imageMainSectionStyle {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    gap: 0;
  }
}

@media screen and (max-width: 1139px) {
  /* .windowFitImgStyle {
    --maximumWidth: 913px;
  } */
}

@media screen and (max-width: 985px) {
  /* .windowFitImgStyle {
    --maximumWidth: 825px;
  } */
}

@media screen and (max-width: 897px) {
  /* .windowFitImgStyle {
    --maximumWidth: 695px;
  } */

  .imageMainContainerStyle {
    max-width: 100%;
    max-height: 100%;
  }

  .homeContainerStyles {
    max-height: 100%;
  }

  .imageWrapperStyle {
    max-width: 100%;
    max-height: 40vh;
  }

  .cropperStyle {
    max-height: 30vh;
    max-width: 80vw;
    height: 100%;
  }

  .homeContainerStyle1,
  .homeContainerStyles {
    padding: 16px;
  }
}

@media screen and (max-width: 767px) {
  /* .windowFitImgStyle {
    --maximumWidth: 477px;
  } */
}

@media screen and (max-width: 549px) {
  /* .windowFitImgStyle {
    --maximumWidth: 302px;
  } */

  .imageAndCropperSectionWrapperStyle {
    gap: 8px;
  }

  .homeContainerHiddenStyle {
    display: none;
  }

  .imageEditBlockStyle {
    padding: 0px;
  }

  .editImgActionIconStyle {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 379px) {
  /* .windowFitImgStyle {
    --maximumWidth: 302px;
  } */
}
